<template>
 <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
  <div class="flex flex-1 justify-between sm:hidden">
   <a
    @click="$emit('previousPage')"
    class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
   >
    {{ $t("ticketTable.previous") }}
   </a>
   <a
    @click="$emit('nextPage')"
    class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
   >
    {{ $t("ticketTable.next") }}
   </a>
  </div>
  <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
   <div class="sm:flex sm:items-center space-x-4">
    <p class="text-sm text-gray-700">
     {{ $t("ticketTable.showing") }}
     {{ " " }}
     <span class="font-medium">{{ from }}</span>
     {{ " " }}
     {{ $t("ticketTable.to") }}
     {{ " " }}
     <span class="font-medium">{{ to }}</span>
     {{ " " }}
     {{ $t("ticketTable.of") }}
     {{ " " }}
     <span class="font-medium">{{ total }}</span>
     {{ " " }}
     {{ $t("ticketTable.results") }}
    </p>

    <select
     @change="$emit('selectRows', $event.target.value)"
     id="location"
     name="location"
     class="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
    >
     <option>{{ $t("ticketTable.selectRows") }}</option>
     <option v-for="(row, idx) in selectRows" :key="idx" :value="row">
      {{ $t("rows", { n: row }) }}
     </option>
    </select>
   </div>
   <div>
    <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
     <a
      v-for="(pagination, idx) in links"
      :key="idx"
      @click="$emit('selectPage', pagination.url)"
      :class="[
       pagination.label.includes('Précédent')
        ? 'rounded-l-md'
        : pagination.label.includes('Suivant')
        ? 'rounded-r-md'
        : parseInt(pagination.label) === currentPage
        ? 'z-10 body__table-backend-pagination--button body__table-backend-pagination--border body__table-backend-pagination--text'
        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
       ,
       'cursor-pointer relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
      ]"
     >
      <span v-if="pagination.label.includes('Précédent')" class="rounded-l-md">
       <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
      </span>
      <span v-else-if="pagination.label.includes('Suivant')">
       <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
      </span>
      <span v-else>
       {{ pagination.label }}
      </span>
     </a>
    </nav>
   </div>
  </div>
 </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
export default {
 name: "BackendPagination",
 props: [
  "data",
  "currentPage",
  "firstPageUrl",
  "from",
  "lastPage",
  "lastPageUrl",
  "links",
  "nextPageUrl",
  "path",
  "perPage",
  "prevPageUrl",
  "to",
  "total",
 ],
 components: { ChevronLeftIcon, ChevronRightIcon },
 data() {
  return {
   page: 1,
   rows: 20,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
 methods: {
  changePage(event) {
   this.page = event.target.value;
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tableData.length;
   } else {
    return this.rows * this.page;
   }
  },
  setRows(event) {
   this.selectedRows = event.target.value;
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);

   let trimmedData = this.tableData.slice(trimStart, trimEnd);

   let pages = Math.ceil(this.tableData.length / this.rows);

   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
 },
};
</script>

<style></style>
