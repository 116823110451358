<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess" />
 <div class="">
  <PbxHeader
   @openForm="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
   :buttonTitle="$t('pbx.newGroup')"
   v-model:search="search"
   pbxMenuType="reportList"
   @select-hostname="this.selectHostname = $event" />
  <!-- <EditReport
   v-if="this.openEditForm"
   @closeEditForm="this.openEditForm = false"
   :name="this.reportName"
   :id="this.reportId"
   :emails="this.reportEmail"
   :cron="this.reportCron"
   :urlType="this.urlType"
   @activeBanner="(activeBanner = true), getReportList(), (key = !key), (this.openEditForm = false)"
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
  /> -->
  <div class="flex flex-col" v-if="data?.data?.length > 0">
   <div class="-my-0 overflow-x-auto py-8">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-20">
     <div
      class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-md">
      <table class="min-w-full divide-y divide-gray-300 dark:divide-slate-400">
       <thead class="bg-gray-50">
        <tr>
         <!-- Render the th element only if header.name is not 'id' -->
         <template v-for="(header, index) in thead">
          <th
           v-if="header.name !== 'id'"
           :key="index"
           scope="col"
           :class="{ 'pl-4 pr-3 sm:pl-6': index === 1, 'px-3': index !== 1 }"
           class="py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
           <span v-if="header.name.includes('_')">
            {{ $t(header.name.split("_")[1]) }}
           </span>
           <span v-else-if="header.name === 'repeat'"> Planifié </span>
           <span v-else>
            {{ $t(header.name) }}
           </span>
          </th>
         </template>
         <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span class="sr-only">Edit</span>
         </th>
        </tr>
       </thead>
       <tbody class="divide-y divide-gray-200 bg-white truncate">
        <slot :row="row" v-for="(row, rowIndex) in tbody" :key="rowIndex">
         <tr>
          <!-- Render the td element only if the key is not 'id' -->
          <template v-for="(value, key, index) in row">
           <td
            v-if="key !== 'id'"
            :key="key"
            :class="{
             'pl-4 pr-3 sm:pl-6 font-medium text-gray-900': index === 1,
             'px-3 text-gray-500': index !== 1,
            }"
            class="whitespace-nowrap text-sm py-4 truncate max-w-sm">
            <span class="truncate" v-if="value">
             <span v-if="key === 'start' || key === 'end'">
              {{ $d(new Date(value), "longNumb", "fr") }}
             </span>
             <span v-else-if="key === 'repeat'">
              <div v-if="value">
               <span class="px-2 py-1 bg-green-200 text-green-600 rounded-xl"
                >Oui</span
               >
              </div>
              <div v-else>
               <span class="px-2 py-1 bg-blue-300 text-blue-600">Non</span>
              </div>
             </span>
             <span v-else>
              {{ value }}
             </span>
            </span>
            <span v-else>
             <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5">
              <path
               d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
             </svg>
            </span>
           </td>
          </template>
          <td
           class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center gap-2">
           <!-- <button class="p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1" @click="$emit('openEditForm')" :title="$t('edit')">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                 <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                 <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                </svg>
               </button> -->
           <a
            :href="`https://presentation.cx-engine.net/${row.report_type}/${row.id}`"
            class="text-main-color text-main-color--hover"
            :title="$t('view')"
            >{{ $t("view") }}</a
           >

           <a
            href="javascript:void(0)"
            @click.prevent="
             deleteItem(
              row.id,
              $t('deleteItem'),
              $t('confirmationMessage'),
              $t('confirm'),
              $t('goBack'),
              $t('successMsg')
             )
            "
            class="text-red-400 hover:text-red-500"
            :title="$t('delete')">
            {{ $t("delete") }}</a
           >
          </td>
         </tr>
        </slot>
       </tbody>
      </table>
      <PaginationComponent
       :current_page="data.current_page"
       :first_page_url="data.first_page_url"
       :from="data.from"
       :last_page="data.last_page"
       :last_page_url="data.last_page_url"
       :links="data.links"
       :next_page_url="data.next_page_url"
       :per_page="data.per_page"
       :to="data.to"
       :total="data.total"
       @action="$emit('action', $event)"
       @previousPage="previousPage()"
       @nextPage="nextPage()" />
     </div>
    </div>
   </div>
  </div>
  <div v-else class="flex justify-center pt-20">
   <div class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor">
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{ $t("warning.noDataFoundTitle") }}
    </h3>
    <p class="mt-1 text-base text-gray-500">
     <!-- {{ $t("noData") }} -->
    </p>
    <!-- <div class="mt-6">
              <button
                type="button"
                class="rounded inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white body__header--button"
                @click="this.openForm = !this.openForm"
              >
                <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                New Group
              </button>
            </div> -->
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";
// import TableComponent from "../../components/TableComponent.vue";
import BackendPagination from "../../components/BackendPagination.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { PlusIcon } from "@heroicons/vue/solid";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import EditReport from "../../components/PBX/EditReport.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import EmptyStateReportList from "../../components/PBX/EmptyStateReportList.vue";
export default {
 props: ["urlType", "page"],
 components: {
  EditReport,
  EmptyStateReportList,
  Loading,
  PbxDashboard,
  PbxHeader,
  PlusIcon,
  SuccessBanner,
  BackendPagination,
  // TableComponent,
  PaginationComponent,
  AskConfirmationDialog,
 },
 data() {
  return {
   thead: [],
   tbody: [],
   activeBanner: false,
   data: undefined,
   currentSort: "id",
   currentSortDir: "asc",
   dataSeriesCurrentMonth: [],
   dataSeriesStacked: [],
   dataSeriesConcatenated: [],
   elementExtensions: [],
   elementQueues: [],
   elementsReportExtensions: [],
   elementsReportQueues: [],
   extensions: [],
   fullPage: true,
   isAllSelected: "",
   isSuccess: true,
   isLoading: false,
   key: false,
   openEditForm: false,
   periodSelector: undefined,
   pbxMapData: {},
   reportLists: {},
   reportId: null,
   reportName: "",
   reportParameters: "",
   reportEmail: "",
   reportCron: "",
   search: "",
   selectHostname: "",
   successMsg: "",
   textMonth: "",
   timeSelected: [],
  };
 },
 methods: {
  async getReportList() {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports?page=${this.page}&filter[element_type]=${
    this.urlType === "queue" ? "4" : "0"
   }`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.data = res.data;
    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      "repeat_pattern",
     ];
     this.thead = keyArr
      .filter(name => !excludedValues.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbody = this.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
    console.log(
     "file: ExpertStatisticsReportList.vue:129 ~ getReportList ~ res.data:",
     res.data
    );
   } catch (error) {
    this.errorHandling(error);
   } finally {
    this.isLoading = false;
    this.$emit("setLoading", false);
   }
  },
  async deleteItem(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getReportList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  openEditFormId(...args) {
   this.openEditForm = true;
   this.reportId = args[0];
   this.reportName = args[1];
   this.reportEmail = args[3];
   this.reportCron = args[4];
  },
  nextPage() {
   if (parseInt(this.page) + 1 <= this.data?.last_page) {
    this.$router.push(
     `/expert-statistics/reports/${this.urlType}/${parseInt(this.page) + 1}`
    );
   }
  },
  previousPage() {
   if (parseInt(this.page) - 1 >= 1) {
    this.$router.push(
     `/expert-statistics/reports/${this.urlType}/${this.page - 1}`
    );
   }
  },
 },
 mounted() {
  this.getReportList();
 },
 watch: {
  urlType: function (val) {
   this.getReportList();
  },
  page: function (val) {
   this.getReportList();
  },
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
  ]),
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
